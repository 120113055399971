(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('ChatCustomersModalController', ChatCustomersModalController);

    ChatCustomersModalController.$inject = ['$uibModalInstance', 'Profile', '$timeout'];

    function ChatCustomersModalController($uibModalInstance, Profile, $timeout) {
        var vm = this;

        vm.customers = [];
        vm.itemsPerPage = 10;
        vm.page = 0;
        vm.customerPhone = "";

        vm.loadCustomers = loadCustomers;
        vm.selectCustomer = selectCustomer;
        vm.cancel = cancel;

        var searchTimeout;

        function loadCustomers() {
            if (searchTimeout) {
                $timeout.cancel(searchTimeout);
            }

            searchTimeout = $timeout(function () {
                if (vm.customerPhone.length > 2) {
                    Profile.queryByPhone({
                        field: vm.customerPhone,
                        page: vm.page,
                        size: vm.itemsPerPage,
                    }, function (response) {
                        vm.customers = response;
                        angular.forEach(vm.customers, function (customer) {
                            customer.avatar = 'content/images/avatar_profile.png'
                        })
                    }, function (error) {
                        AlertService.error('Error loading messages');
                    });
                }
            }, 1500);
        }

        function selectCustomer(customer) {
            $uibModalInstance.close(customer);
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
