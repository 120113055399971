(function() {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('Chat', Chat)
        .factory('ChatMessage', ChatMessage);


    Chat.$inject = ['$resource', 'DateUtils'];

    function Chat ($resource, DateUtils) {
        var resourceUrl =  'api/chats/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.lastModifiedDate = DateUtils.convertDateTimeFromServer(data.lastModifiedDate);
                    }
                    return data;
                }
            },
            'update': { method: 'PUT' },
            'getByCustomerId': { method: 'GET', url: 'api/chats/customer/:customerId'}
        });
    }

    ChatMessage.$inject = ['$resource', 'DateUtils'];

    function ChatMessage ($resource, DateUtils) {
        var resourceUrl =  'api/chat-messages/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createdDate = DateUtils.convertDateTimeFromServer(data.createdDate);
                        data.lastModifiedDate = DateUtils.convertDateTimeFromServer(data.lastModifiedDate);
                    }
                    return data;
                }
            },
            'getByChatId': {method: 'GET', url: 'api/chat-messages/chat/:chatId', isArray: true},
            'update': { method:'PUT' }
        });
    }
})();
