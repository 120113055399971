(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('GatewayDiagnosticsController', GatewayDiagnosticsController);

    GatewayDiagnosticsController.$inject = ['$scope', '$state', 'TkbTransfer'];

    function GatewayDiagnosticsController($scope, $state, TkbTransfer) {
        var vm = this;

        vm.search = search;
        vm.onChangeMethod = onChangeMethod;
        vm.copyRequest = copyRequest;
        vm.copyResponse = copyResponse;
        vm.serviceInfoId = "4387";
        vm.serviceId = "4386";
        vm.transferAmount = 1000;
        vm.paymentAmount = 100000;
        vm.searchQuery = {
            merchant: 'GLOBIZ',
            gateway: 'TKB',
            method: "checkCardReceipt",
            referenceId: null,
            externalId: null,
            serviceId: "4386",
            account: "977580843",
            cardNumber: "8600312905180812",
            amount: 100000
        }

        function search() {
            TkbTransfer.getGatewayDiagnostics({
                merchant: vm.searchQuery.merchant,
                gateway: vm.searchQuery.gateway,
                method: vm.searchQuery.method,
                referenceId: vm.searchQuery.referenceId,
                externalId: vm.searchQuery.externalId,
                serviceId: vm.searchQuery.serviceId,
                account: vm.searchQuery.account,
                cardNumber: vm.searchQuery.cardNumber,
                amount: vm.searchQuery.amount
            }, onSuccess, onError);
            function onSuccess(data) {
                vm.request = data.requestBody;
                vm.response = data.responseBody;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function onChangeMethod() {
            vm.searchQuery.amount = vm.searchQuery.method === 'createTransfer' ? vm.transferAmount : vm.paymentAmount;
            vm.searchQuery.serviceId = vm.searchQuery.method === 'getPaymentInformation' ? vm.serviceInfoId : vm.serviceId;
        }

         function copyRequest() {
            var jsonElement = document.getElementById("requestJson");

            if (jsonElement) {
                var range = document.createRange();
                range.selectNode(jsonElement);
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(range);

                try {
                    document.execCommand("copy");
                    AlertService.success("JSON copied successfully");
                } catch (err) {
                    AlertService.error("Error coping JSON");
                }

                window.getSelection().removeAllRanges();
            }
        }

        function copyResponse() {
            var jsonElement = document.getElementById("responseJson");

            if (jsonElement) {
                var range = document.createRange();
                range.selectNode(jsonElement);
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(range);

                try {
                    document.execCommand("copy");
                    AlertService.success("JSON copied successfully");
                } catch (err) {
                    AlertService.error("Error coping JSON");
                }
                window.getSelection().removeAllRanges();
            }
        }
    }
})();
